import { CustomizerIntegration, CustomizerIntegrationType, ShopifyOnlineStore } from '@packages/types'
import React, { useEffect, useState } from 'react'

import { Page } from 'cms/layout'
import { ShopifyTheme } from 'cms/onlineStores/types'
import { Button, Card, InputField, Label, Select, useModal } from 'common/components'
import HelpIcon from 'icons/regular/01-Interface Essential/14-Alerts/question-circle.svg'

import ShopifyIntegrationDetails from './ShopifyIntegrationDetails'
import ShopifyIntegrationModal from './ShopifyIntegrationModal'
import ShopifyIntegrationPreview from './ShopifyIntegrationPreview'

interface ShopifyIntegrationSectionProps {
  onlineStore: ShopifyOnlineStore
  themes?: ShopifyTheme[]
}

const ShopifyIntegrationSection = ({ onlineStore, themes }: ShopifyIntegrationSectionProps) => {
  const [selectedTheme, setSelectedTheme] = useState<ShopifyTheme>()
  const [currentIntegration, setCurrentIntegration] = useState<CustomizerIntegration>()
  const shopifyIntegrationModal = useModal()

  useEffect(() => {
    if (themes && !selectedTheme) setSelectedTheme(themes.find(({ role }) => role === 'main'))
  }, [themes])

  useEffect(() => {
    if (!selectedTheme) return

    const existingIntegration = onlineStore.customizerIntegrations?.find(({ themeId }) => themeId == selectedTheme.id)
    setCurrentIntegration(
      existingIntegration || { themeId: selectedTheme.id, integrationType: CustomizerIntegrationType.CustomizerOnly }
    )
  }, [selectedTheme, onlineStore.customizerIntegrations])

  const openHelp = () => {
    window.open('https://help.gokickflip.com/en/articles/6289000-integrating-your-customizer-in-shopify', '_blank')
  }

  const options = themes?.map(({ id, role, name }) => ({ value: id, label: role === 'main' ? `${name} (live)` : name }))

  return (
    <Page.Section>
      <Page.Aside
        title="Customizer integration"
        description="Decide how your customers will access your customizer from your e-commerce."
      >
        <Button
          aria-label="Shopify integration help"
          className="mt-4"
          iconPosition="right"
          icon={<HelpIcon className="w-4 h-4" />}
          onClick={openHelp}
        >
          Learn more
        </Button>
      </Page.Aside>

      <Page.Content>
        <Card className="flex flex-1 flex-col">
          <Card.Section className="md:flex-col space-y-6">
            {selectedTheme && themes ? (
              <InputField>
                <Label>Select an e-commerce theme</Label>
                <Select
                  aria-label="theme-integration"
                  id="theme-integration"
                  name="theme-integration"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  value={options?.find(option => option.value === selectedTheme.id) || null}
                  options={options}
                  onChange={option => setSelectedTheme(themes?.find(theme => theme.id === option?.value))}
                />
              </InputField>
            ) : (
              <div className="animate-pulse h-8 bg-neutral-75 rounded" />
            )}
          </Card.Section>
          <Card.Separator />
          <Card.Section className="space-x-6 items-center">
            {currentIntegration ? (
              <>
                <ShopifyIntegrationPreview integrationType={currentIntegration.integrationType} className="flex-1" />
                <ShopifyIntegrationDetails
                  currentIntegration={currentIntegration}
                  onClick={shopifyIntegrationModal.open}
                />
              </>
            ) : (
              <div className="animate-pulse h-60 bg-neutral-75 rounded w-full" />
            )}
          </Card.Section>
        </Card>
      </Page.Content>

      {selectedTheme && currentIntegration && shopifyIntegrationModal.isVisible && (
        <ShopifyIntegrationModal
          onClose={shopifyIntegrationModal.close}
          onlineStore={onlineStore}
          selectedTheme={selectedTheme}
          currentIntegration={currentIntegration}
          {...shopifyIntegrationModal.modalProps}
        />
      )}
    </Page.Section>
  )
}

export default ShopifyIntegrationSection
