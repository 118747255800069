import { ShopifyOnlineStore } from '@packages/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'

import { Page } from 'cms/layout'
import { useShopifyProxyService } from 'cms/onlineStores'
import { ShopifyTheme } from 'cms/onlineStores/types'
import { Button, Card, InputField, Label, Select, useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'
import HelpIcon from 'icons/regular/01-Interface Essential/14-Alerts/question-circle.svg'

import ShopifyThemeVersioningAlert from './ShopifyThemeVersioningAlert'

interface ShopifyThemeSectionProps {
  onlineStore: ShopifyOnlineStore
  themes?: ShopifyTheme[]
}

const ShopifyThemeSection = ({ onlineStore, themes }: ShopifyThemeSectionProps) => {
  const [selectedTheme, setSelectedTheme] = useState<ShopifyTheme>()
  const { openToast } = useToast()
  const shopifyProxyService = useShopifyProxyService()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (themes) setSelectedTheme(themes.find(({ role }) => role === 'main'))
  }, [themes])

  const { mutate: updateCart, isLoading: isUpdatingCart } = useMutation(
    () => shopifyProxyService.updateCart(onlineStore.id, { themeId: selectedTheme!.id }),
    {
      onSuccess: () => openToast(`Successfully updated ${selectedTheme?.name} cart templates.`, ToastType.success),
      onError: () => {
        openToast(
          `There was an error while trying to update ${selectedTheme?.name} cart templates. Please read our docs in order to do it manually.`,
          ToastType.warning
        )
      },
    }
  )

  const { mutate: updateProduct, isLoading: isUpdatingProduct } = useMutation(
    () => shopifyProxyService.updateProduct(onlineStore.id, { themeId: selectedTheme!.id }),
    {
      onSuccess: () => {
        openToast(`Successfully updated ${selectedTheme?.name} product templates.`, ToastType.success)
        // When we do the get to check version it seems like the version is not up updated in template. Seting query data to a version that would be up to date fix this
        queryClient.setQueryData(
          [...shopifyProxyService.fetchThemeAssetVersions.queryKeys, onlineStore.id, selectedTheme!.id],
          { version: '1.0.0', lastVersion: '1.0.0' }
        )
      },
      onError: () => {
        openToast(
          `There was an error while trying to update ${selectedTheme?.name} product templates.`,
          ToastType.warning
        )
      },
    }
  )

  const options = themes?.map(({ id, role, name }) => ({ value: id, label: role === 'main' ? `${name} (live)` : name }))

  return (
    <Page.Section>
      <Page.Aside title="Shopify theme settings" description="Update your themes templates to work with Kickflip">
        <Button
          aria-label="Shopify theme help"
          className="mt-4"
          iconPosition="right"
          icon={<HelpIcon className="w-4 h-4" />}
          onClick={() =>
            window.open('https://help.gokickflip.com/en/articles/4586871-setting-up-your-shopify-cart-page', '_blank')
          }
        >
          Learn more
        </Button>
      </Page.Aside>

      <Page.Content>
        <Card className="flex flex-1 flex-col">
          <Card.Section className="md:flex-col space-y-6">
            {!selectedTheme && <div className="animate-pulse h-8 bg-neutral-75 rounded" />}
            {selectedTheme && (
              <InputField>
                <Label>Select an e-commerce theme</Label>
                <Select
                  aria-label="theme-settings"
                  id="theme-settings"
                  name="theme-settings"
                  placeholder=""
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  value={options?.find(option => option.value === selectedTheme.id) || null}
                  options={options}
                  onChange={option => setSelectedTheme(themes?.find(theme => theme.id === option?.value))}
                />
              </InputField>
            )}

            <ShopifyThemeVersioningAlert onlineStore={onlineStore} selectedTheme={selectedTheme} />

            <div className="flex justify-end space-x-3">
              <Button
                disabled={!selectedTheme || isUpdatingCart}
                isLoading={isUpdatingCart}
                onClick={() => updateCart()}
              >
                Update cart
              </Button>
              <Button
                disabled={!selectedTheme || isUpdatingProduct}
                isLoading={isUpdatingProduct}
                onClick={() => updateProduct()}
              >
                Update product
              </Button>
            </div>
          </Card.Section>
        </Card>
      </Page.Content>
    </Page.Section>
  )
}

export default ShopifyThemeSection
