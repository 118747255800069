import React from 'react'

import { Alert, Input } from 'common/components'
import { useCopyToClipboard } from 'common/hooks'

export interface ConnectionWarningAlertProps {
  connectionString: string
}

const ConnectionWarningAlert = ({ connectionString }: ConnectionWarningAlertProps) => {
  const copyToClipboard = useCopyToClipboard()

  return (
    <Alert className="mt-6" variant="warning">
      <Alert.Body>
        <Alert.Title>We can't establish a connection with your online store.</Alert.Title>
        <Alert.Details className="mb-4">
          Make sure the URL is valid. If you're developing locally or your server is unreachable for other reason you
          can copy the sync code below and use it in the Kickflip app settings on your online store admin.
        </Alert.Details>
      </Alert.Body>

      <Input
        className="bg-transparent border-neutral-300"
        inputClassName="bg-transparent"
        value={connectionString}
        readOnly
        rightAddon={
          <button
            type="button"
            className="whitespace-nowrap w-fit ml-6"
            onClick={() => copyToClipboard(connectionString)}
          >
            Copy code
          </button>
        }
      />
    </Alert>
  )
}

export default ConnectionWarningAlert
