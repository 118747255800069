import { ECommerce } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import CustomStoreLogo from 'icons/custom/custom-store.svg'
import PrestashopLogo from 'icons/custom/prestashop.svg'
import ShopifyLogo from 'icons/custom/shopify-icon.svg'
import WixLogo from 'icons/custom/wix-icon.svg'
import WoocommerceLogo from 'icons/custom/woocommerce-icon.svg'

const logos = {
  shopify: ShopifyLogo,
  woocommerce: WoocommerceLogo,
  prestashop: PrestashopLogo,
  wix: WixLogo,
  'custom-store': CustomStoreLogo,
}

export const labels = {
  shopify: 'Shopify logo',
  woocommerce: 'WooCommerce logo',
  prestashop: 'PrestaShop logo',
  wix: 'Wix logo',
  'custom-store': 'Custom store logo',
}

interface ECommerceLogoProps {
  eCommerce: ECommerce
  circle?: boolean
  className?: string
}

const ECommerceLogo = ({ eCommerce, circle, className }: ECommerceLogoProps) => {
  const Logo = logos[eCommerce]

  return (
    <div className={classNames('relative flex justify-center items-center text-center', className)}>
      {circle && <div className="absolute w-full h-full rounded-full bg-neutral-100 content-['']" />}
      {Logo && (
        <Logo
          className={classNames('relative', {
            'p-2': circle && eCommerce !== ECommerce.CustomStore,
            'p-3': circle && eCommerce === ECommerce.CustomStore,
            'h-full w-full': circle,
            'h-4 w-4': !circle,
          })}
          data-testid="ecommerceLogo"
          aria-label={labels[eCommerce]}
        />
      )}
    </div>
  )
}

export default ECommerceLogo
