import { ShopifyOnlineStore } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

import { Page } from 'cms/layout'
import { useShopifyProxyService } from 'cms/onlineStores'

import ShopifyIntegrationSection from './ShopifyIntegrationSection'
import ShopifyMultiCurrency from './ShopifyMultiCurrency'
import ShopifyOnlineStoreInfo from './ShopifyOnlineStoreInfo'
import ShopifyThemeSection from './ShopifyThemeSection'

interface ShopifyOnlineStoreProps {
  onlineStore: ShopifyOnlineStore
}

const ShopifyOnlineStoreContent = ({ onlineStore }: ShopifyOnlineStoreProps) => {
  const shopifyProxyService = useShopifyProxyService()

  const { data: themes } = useQuery([...shopifyProxyService.fetchThemes.queryKeys, onlineStore.id], () =>
    shopifyProxyService.fetchThemes(onlineStore.id)
  )

  return (
    <>
      <ShopifyOnlineStoreInfo onlineStore={onlineStore} />
      {!!onlineStore.currencies?.length && (
        <>
          <Page.Separator />
          <ShopifyMultiCurrency />
        </>
      )}
      <Page.Separator />
      <ShopifyThemeSection themes={themes} onlineStore={onlineStore} />
      <Page.Separator />
      <ShopifyIntegrationSection themes={themes} onlineStore={onlineStore} />
    </>
  )
}

export default ShopifyOnlineStoreContent
